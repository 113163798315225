import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { alt, metaTeam } from '../lib/brand';
import Layout from '../components/Layout/';
import Header from '../components/Header';
import TeamDetail from '../components/TeamDetail';

const Team = ({ data }) => {
   const { profile, siteMeta } = data;
   const {
      contact,
      applyNow,
      applyNowScript,
      logIn,
      profile: teamProfile,
      headBeginScript,
      headEndScript,
      bodyBeginScript,
      bodyEndScript,
      title,
      slug,
      news
   } = profile;
   const {
      siteMetadata: { title: siteTitle }
   } = siteMeta;
   const { branch } = teamProfile;
   let relatedProps = {
      branch,
      corporateBlogs: news,
      siteTitle
   };

   // get dba logo from branch
   const dbaLogoBlack = get(relatedProps, 'branch.dbaLogoBlack', null);

   const teamBug = {
      ...teamProfile,
      applyNow,
      applyNowScript,
      logIn,
      slug
   };

   const header = <Header active={[]} theme="white-ribbon" dbaBlack={dbaLogoBlack} clearDba={true} />;
   const pagetitle = alt(`${title} - ${relatedProps.branch.city}, ${relatedProps.branch.state}`);
   return (
      <Layout
         hasHero={true}
         header={header}
         bug={false}
         teamData={teamBug}
         headBeginScript={headBeginScript}
         headEndScript={headEndScript}
         bodyBeginScript={bodyBeginScript}
         bodyEndScript={bodyEndScript}>
         <Helmet>
            <title>{pagetitle}</title>
            <meta name="description" content={metaTeam(title)} />
            <meta http-equiv="X-UA-Compatible" content="IE=11" />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <TeamDetail {...profile} {...teamProfile} {...contact} {...relatedProps} isPreview={false} />
      </Layout>
   );
};

export default Team;

export const pageQuery = graphql`
   query TeamPageById($id: String!) {
      profile(id: { eq: $id }) {
         id
         firestoreId
         slug
         headBeginScript {
            name
            src
            async
            html
         }
         headEndScript {
            name
            src
            async
            html
         }
         bodyBeginScript {
            name
            src
            async
            html
         }
         bodyEndScript {
            name
            src
            async
            html
         }
         title
         owner
         applyNow
         applyNowScript
         logIn
         blogs {
            id
            html
            slug
            title
            author {
               slug
               profile {
                  name
               }
               gallery {
                  photo
               }
            }
            date
            feature
            categories
            image
         }
         news {
            id
            html
            slug
            title
            date
            feature
            categories
            image
         }
         profile {
            name
            email
            logo
            graphic
            licenseIds {
               license
               id
            }
            branch {
               slug
               dbaLogoBlack
               title
               address1
               address2
               address3
               city
               state
               zip
               lat
               lon
            }
         }
         hero {
            quote
            byline
            heroBackground
            customPhoto
            photoAlignment
         }
         contact {
            phone
            mobilePhone
            fax
            facebook
            instagram
            linkedin
            twitter
            youtube
         }
         allowLink
         loanOfficers {
            slug
            hidden
            gallery {
               photo
            }
            profile {
               name
               email
               jobTitle
               licenseIds {
                  license
                  id
               }
            }
         }
         staff {
            photo
            name
            title
            license
            email
         }
         managers {
            photo
            name
            title
            license
            email
         }
         modules {
            type
            display
            videos {
               videoTitle
               videoCaption
               videoUrl
            }
            facebookPageUrl
            bookings
            calendar
            useBackgroundImage
            backgroundImage
            backgroundImageCustom
            darkTitleText
            instagramToken
            resourceIntro
            resourceList {
               resourceFile
               resourceUrl
               resourceDescription
            }
            event1 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            event2 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            event3 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            awards {
               awardDescription
               awardYear
               awardImage
               awardBadge
            }
            social_id
            social_page
            showCalculatorMortgage
            showCalculatorRentVsBuy
            showCalculatorHomeAffordability
            showCalculatorRequiredIncome
            showCalculatorRefinance
            contentImage
            contentTitle
            contentText
            profileShowCompactView
            profileTitle
            profileBody
            contactFormFields {
               type
               required
               customFieldType
               label
               bammName
               options {
                  hasCustomLabel
                  label
                  value
               }
            }
            contactFormTitle
            contactUsText
            contactUsTitle
            contactUsBammUrl
            contactUsLeadEmailCcList
            contactUsConfirmEmailText
            contactUsConfirmEmailFrom
            id
         }
      }
      siteMeta: site {
         siteMetadata {
            title
         }
      }
   }
`;
